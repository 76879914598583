import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import ContactForm from '../components/ContactForm/ContactForm';

const ContactPage = ({ data }) => (
  <Layout>
    <SEO title={data.content.frontmatter.meta.seo_title} description={data.content.frontmatter.meta.seo_description} />
    <div className="header-separator" style={{ paddingTop: '64px' }} />
    <ContactForm content={data.content.frontmatter} />
  </Layout>
);

export default ContactPage;

export const pageQuery = graphql`
  query($language: String!) {
    content: markdownRemark(
      collection: { eq: "pages" }
      folder: { eq: "contact" }
      fields: { language: { eq: $language } }
    ) {
      frontmatter {
        meta {
          name
          slug
          seo_title
          seo_description
        }
        contact {
          headline
          text
        }
      }
    }
  }
`;
